import React from 'react'
import { graphql } from 'gatsby'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '/src/components/layout'
import { ProductList, ProductListFilters } from '/src/components/commerce'

const AllProductsPage = ({ data }) => {
  const { allCommerceProduct } = data

  return (
    <Layout>
      <Row className="mb-5">
        <Col md={4}>
          <ProductListFilters />
        </Col>
        <Col md={8}>
          <ProductList products={allCommerceProduct.edges} />
        </Col>
      </Row>
    </Layout>
  )
}

export default AllProductsPage

export const Head = () => (
  <>
    <title>All Products | Orrcon Steel</title>
    <meta name="description" content="Orrcon Steel is a leading Australian distributor and manufacturer of steel, tube and pipe. Our extensive product range covers RHS, SHS & CHS structural tubular steel, hot rolled structural steel and a variety of fencing, roofing and building accessories." />
    <meta name="og:description" property="og:description" content="Your One-Stop Shop for Effortless Ordering of Premium Steel Products. Buy online or call us today. At Orrcon Steel, we’ll see it through" />
    <meta name="og:description" property="og:description" content="Your One-Stop Shop for Effortless Ordering of Premium Steel Products. Buy online or call us today. At Orrcon Steel, we’ll see it through" />
    <meta property="og:image" content="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/og_orrcon.png" />
  </>
)

export const query = graphql`
{
  allCommerceProduct {
    edges {
      node {
        id
        title
        path {
          alias
        }
        relationships {
          field_product_category {
            name
          }
          field_product_images {
            relationships {
              field_media_image_2 {
                gatsbyImage(
                  fit: COVER
                  formats: AUTO
                  height: 180
                  width: 180
                  layout: FIXED
                  quality: 100
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
  }
}
`
